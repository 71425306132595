'use strict';

/**
 * Manages communications with Companion
 */
export { default as RequestClient } from './RequestClient.js';
export { default as Provider } from './Provider.js';
export { default as SearchProvider } from './SearchProvider.js';

// TODO: remove in the next major
export { default as Socket } from './Socket.js';